<template>
  <div>
    <symbol id="check-square-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
      <path d="M10.97 4.97a.75.75 0 0 1 1.071 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.235.235 0 0 1 .02-.022z"/>
    </symbol>
    <symbol id="exclamation-square-fill" fill="currentColor" viewBox="0 0 16 16">
      <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
      <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z"/>
    </symbol>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <CRow class="mx-0 mb-2">
      <CCol sm="12" class="d-flex align-items-center justify-content-start">
        <CButton
          color="edit"
          class="mr-2"
          v-c-tooltip="{
            content: $t('label.return'),
            placement: 'top',
          }"
          size="sm"
          @click="Reset()"
        >
          <CIcon name="cil-chevron-left-alt" />
        </CButton>
        <h6 class="mt-2">
          {{ `${$t('label.file')}:`}} 
        </h6>
        <strong class="ml-1">
          {{ `${ListDetails?.FileName ?? ''}`}} 
        </strong>
      </CCol>
    </CRow>
    <CRow class="m-0">
      <CCol col="12">
        <div class="d-flex align-items-center justify-content-end">
          <CButton
            color="excel"
            size="sm"
            @click="onBtnExport(true)"
          >
            <CIcon name="cil-file-excel"/>&nbsp; XSLX
          </CButton>
        </div>
      </CCol>
      <CCol col="12" lg="6">
        <div class="form-group form-row mb-0" rol="group">
          <label class="col-form-label-sm col-sm-12 col-lg-auto mb-0 text-right">
            {{$t('label.openErrors')}}
          </label>
          <div class="col-sm-12 col-lg-auto mb-2">
            <div class="d-flex justify-content-end">
              <CSwitch
                color="watch"
                variant="3d"
                type="checkbox"
                :checked.sync="FgError"
                @update:checked="FgError=$event"
              />
            </div>
          </div>
        </div>
      </CCol>
      <CCol col="12" lg="12">
        <dataTableExtended
          class="align-center-row-datatable"
          sorter
          column-filter
          :items="formatedItems"
          :fields="fields"
          :items-per-page="5"
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          @row-clicked="RowClicked"
          @filtered-items-change="filteredList=$event"
          pagination
        >
          <template #FgError="{item}">
            <td class="text-center">
              <div class="animation-item" v-if="item.FgError">
                <h3 class="text-danger"><svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-square-fill"/></svg></h3>
              </div>
              <div class="animation-item" v-else>
                <h3 class="text-success"><svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-square-fill"/></svg></h3>
              </div>
            </td>
          </template>
          <template #GeneralInfo="{item}">
            <td class="text-center">
              {{ item.GeneralInfo ? (item.GeneralInfo.length > 50 ? RemoveSpecialCharacters(item.GeneralInfo, 50) : item.GeneralInfo) : '', }}
            </td>
          </template>
          <template #Observation="{item}">
            <td class="text-center">
              {{ item.Observation ? (item.Observation.length > 50 ? RemoveSpecialCharacters(item.Observation, 50) : item.Observation) : '', }}
            </td>
          </template>
          <template #DescriptionOfGoods="{item}">
            <td class="text-center">
              {{ item.DescriptionOfGoods ? (item.DescriptionOfGoods.length > 50 ? RemoveSpecialCharacters(item.DescriptionOfGoods, 50) : item.DescriptionOfGoods) : '', }}
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <ModalError
      :modal="ModalError"
      :File="FileError"
      :isDetails="true"
      @Close="(ModalError=false, FileError={})"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { NumberFormater, trimRemoveSpecialCharacters } from '@/_helpers/funciones';
import GeneralMixin from '@/_mixins/general';
import Reportes from '@/_mixins/reportes';
import ModalError from './modal-error';

//DATA-------------------------
function data() {
  return {
    ModalError: false,
    Loading: false,
    FgError: false,
    filteredList: [],
    ListDetails: {},
    FileError: {},
  }
}

async function getStowagePlanningFileGeneralCargo() {
  this.Loading = true;
  await this.$http.get('StowagePlanningFileGeneralCargo-by-Id', { StowagePlanningFileId: this.StowagePlanningFileId })
    .then(response => {
      this.ListDetails = response?.data?.data[0] ?? {};
    })
    .catch( err => {
      this.$notify({
        group: 'yard',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    })
    .finally(() => {
      this.Loading = false;
    });
}

function RowClicked(item) {
  if(item.FgError){
    this.FileError = item;
    this.ModalError = true;
  }
}

async function onBtnExport(Excel) {
  this.Loading = true;
  let title = this.ListDetails?.FileName ?? '';
  let ErrorJson = [];
  let TableHeader = {
    label: [],
    key: [],
  }
  if (this.filteredList && this.filteredList.length != 0) {
    await this.fields.forEach((item, index) => {
      if (!index == 0) {
        TableHeader.label.push(item.label);
        TableHeader.key.push(item.key);
      }
    });
    TableHeader.label.push(this.$t('label.errors'));
    TableHeader.key.push('Errors');
    ErrorJson = this.filteredList.map(item => {
      return {
        ...item,
        Weight: item.Weight ? NumberFormater.setNum(item.Weight) : 0,
        Volumen: item.Volumen ? NumberFormater.setNum(item.Volumen) : 0,
        Quantity: item.Volumen ? NumberFormater.setNum(item.Quantity) : 0,
      }
    });
    await this.getExcelArray(ErrorJson, title, Excel, undefined, true, undefined, TableHeader)
      .catch((err) => {
        this.Loading = false;
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
      })
  }else{
    this.$notify({
      group: "container",
      title: "¡Error!",
      text:  this.$t('label.noRecordsAvailbleReport'),
      type: "error",
    });
  }
  this.Loading = false;
}

function RemoveSpecialCharacters(Value, limit) {
  if (Value) {
    return trimRemoveSpecialCharacters(Value, limit)
  }else{
    return '';
  }
}

function Reset() {
  this.filteredList = [];
  this.ListDetails = {};
  this.FileError = {};
  this.FgError = false;
  this.$emit('Close');
}

//computed
function formatedItems() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let List = [];
  if (this.ListDetails?.TpStowageFileId?.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_CONTAINER_LIST) {
    List = this.ListDetails?.CargoJson ?? [];
    if (this.FgError) {
      List = List.filter(item => item.FgError);
    }
    return List.map((item) => {
      return {
        ...item,
        Nro: Number.parseInt(item.Nro),
        ContainerCode: item.ContainerCode ?? '',
        BlNro: item.BlNo ?? '',
        TpCargoCode: item.TpCargoCode ?? '',
        Consignee: item.Consignee ?? '',
        IsoCode: item.IsoCode ?? '',
        Status: item.Status ?? '',
        Seals: item.Seals ?? '',
        ShippingLineCode: item.ShippingLineCode ?? '',
        PortActivityName: item.PortActivityName ?? '',
        Condition: item.Condition ?? '',
        ImdgCode: item.ImdgCode ?? '',
        LoadPort: item.LoadPort ?? '',
        DischargePort: item.DischargePort ?? '',
        Weight: NumberFormater.formatNumber(item.Weigth, 2),
        DescriptionOfGoods: item.DescriptionOfGoods ?? '',
        ErrorJson: item.JsonError ?? [],
        Errors: item.JsonError.map((Error) => {
          return `${this.$t("label.column")} ${Error.ColumnName}: ${Error['ErrorMessage'+_lang]??''}`;
        }).join(","),
        _style: item.FgError ? 'cursor: pointer;' : '',
      };
    })
  } else if (this.ListDetails?.TpStowageFileId?.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_BILL_OF_LADING) {
    List = this.ListDetails?.StowagePlanningBlJson ?? [];
    if (this.FgError) {
      List = List.filter(item => item.FgError);
    }
    return List.map((item) => {
      let ExternalPackaging = [];
      let InternalPackaging = [];
      item?.PackagingJson.forEach(Packaging => {
        if (Packaging.FgExternPackaging) {
          ExternalPackaging.push(Packaging['PackagingName'+_lang]??'');
        } else {
          InternalPackaging.push(Packaging['PackagingName'+_lang]??'');
        }
      })
      return {
        ...item,
        Nro: Number.parseInt(item.Nro),
        BlNro: item.BlNro ?? '',
        TpCargoCode: item.TpCargoCode ?? '',
        ClassificationName: item.ClassificationName ?? '',
        GeneralInfo: item.GeneralInfo ?? '',
        ExternalPackaging: ExternalPackaging.join(', '),
        InternalPackaging: InternalPackaging.join(', '),
        Weight: NumberFormater.formatNumber(item.Weight, 2),
        Volumen: NumberFormater.formatNumber(item.Volumen, 2),
        Quantity: NumberFormater.formatNumber(item.Quantity, 2),
        ImdgCode: item.ImdgCode ?? '',
        ClientRif: item.ClientRif ?? '',
        ClientName: item.ClientName ?? '',
        LoadPort: item.LoadPort ?? '',
        DischargePort: item.DischargePort ?? '',
        Direct: item.FgDirect ? this.$t('label.yes') : this.$t('label.no'),
        Seller: item.Seller ?? '',
        NotifyTo: item.NotifyTo ?? '',
        Observation: item.Observation ?? '',
        ErrorJson: item.JsonError ?? [],
        Errors: item.JsonError.map((Error) => {
          return `${this.$t("label.column")} ${Error.ColumnName}: ${Error['ErrorMessage'+_lang]??''}`;
        }).join(","),
        _style: item.FgError ? 'cursor: pointer;' : '',
      };
    })
  }
}

function fields(){
  if (this.ListDetails?.TpStowageFileId?.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_CONTAINER_LIST) {
    return [
      { key: 'FgError', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
      { key: 'Nro', label: '#', _classes: 'text-center', filter: false },
      { key: 'ContainerCode', label: this.$t('label.containerCode'),_classes:"text-center", _style:'min-width:205px;'},
      { key: 'BlNro', label: 'BL NRO',_classes:"text-center", _style:'min-width:180px;'},
      { key: 'TpCargoCode', label: this.$t('label.type'),_classes:'text-center', _style:'min-width:140px;'},
      { key: 'Consignee', label: this.$t('label.consignee'),_classes:"text-center", _style:'min-width:200px;'},
      { key: 'IsoCode', label: this.$t('label.isoCode'),_classes:'text-center', _style:'min-width:140px;'},
      { key: 'Status', label: this.$t('label.status'),_classes:'text-center', _style:'min-width:140px;'},
      { key: 'Seals', label: this.$t('label.Seal')+'S',_classes:'text-center', _style:'min-width:200px;'},
      { key: 'ShippingLineCode', label: this.$t('label.newShippingLine'),_classes:'text-center', _style:'min-width:140px;'},
      { key: 'PortActivityName', label: this.$t('label.portActivity'),_classes:'text-center', _style:'min-width:190px;'},
      { key: 'Condition', label: this.$t('label.condition'),_classes:'text-center', _style:'min-width:140px;'},
      { key: 'ImdgCode', label: 'IMDG',_classes:'text-center', _style:'min-width:140px;'},
      { key: 'LoadPort', label: 'POL',_classes:'text-center', _style:'min-width:150px;'},
      { key: 'DischargePort', label: 'POD',_classes:'text-center', _style:'min-width:150px;'},
      { key: 'Weight', label: this.$t('label.weight')+' (KGM)',_classes:'text-center', _style:'min-width:140px;'},
      { key: 'DescriptionOfGoods', label: this.$t('label.DescriptionOfGoods'),_classes:'text-center', _style:'min-width:250px;'},
    ]
  } else if(this.ListDetails?.TpStowageFileId?.toUpperCase() == process.env.VUE_APP_TP_STOWAGE_FILE_ID_BILL_OF_LADING) {
    return [
      { key: 'FgError', label: '', _style: 'min-width:45px;', sorter: false, filter: false },
      { key: 'Nro', label: '#', _classes: 'text-center', filter: false },
      { key: 'BlNro', label: 'BL NRO', _classes: 'text-center', _style: 'min-width: 180px;'},
      { key: 'TpCargoCode', label: this.$t('label.cargoType'), _classes: 'text-center', _style: 'min-width: 150px;'},
      { key: 'ClassificationName', label: this.$t('label.classification'), _classes: 'text-center', _style: 'min-width: 140px;'},
      { key: 'Commodity', label: this.$t('label.commodity'), _classes: 'text-center', _style: 'min-width: 150px;'},
      { key: 'GeneralInfo', label: this.$t('label.generalInfo'), _classes: 'text-center', _style: 'min-width: 200px;'},
      { key: 'ExternalPackaging', label: this.$t('label.ExternalPackaging'), _classes: 'text-center', _style: 'min-width: 180px;'},
      { key: 'InternalPackaging', label: `${this.$t('label.InternalPackaging')}`,  _classes: 'text-center', _style: 'min-width: 180px;'},
      { key: 'Weight', label: this.$t('label.weight')+' (KGM)', _classes: 'text-center', _style: 'min-width: 135px;'},
      { key: 'Volumen', label: this.$t('label.volume')+' (M³)', _classes: 'text-center', _style: 'min-width: 155px;'},
      { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-center', _style: 'min-width: 175px;'},
      { key: 'ImdgCode', label: 'IMDG', _classes: 'text-center', _style: 'min-width: 130px;'},
      { key: 'ClientRif', label: this.$t('label.clientRif'), _classes: 'text-center', _style: 'min-width: 150px;'},
      { key: 'ClientName', label: this.$t('label.client'), _classes: 'text-center', _style: 'min-width: 250px;'},
      { key: 'LoadPort', label: 'POL', _classes: 'text-center', _style: 'min-width: 150px;'},
      { key: 'DischargePort', label: 'POD', _classes: 'text-center', _style: 'min-width: 150px;'},
      { key: 'Direct', label: this.$t('label.DirectDischarge'), _classes: 'text-center', _style: 'min-width: 180px;'},
      { key: 'Seller', label: this.$t('label.seller'), _classes: 'text-center', _style: 'min-width: 200px;'},
      { key: 'NotifyTo', label: this.$t('label.notifyParty'), _classes: 'text-center', _style: 'min-width: 180px;'},
      { key: 'Observation', label: this.$t('label.observation'), _classes: 'text-center', _style: 'min-width: 250px;'},
    ];
  }else{
    return []
  }
 }

export default {
  name: 'file-details',
  components: {
    ModalError,
  },
  data,
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    StowagePlanningFileId: {
      type: String,
      default: () => '',
    }
  },
  mixins: [GeneralMixin, Reportes],
  methods: {
    getStowagePlanningFileGeneralCargo,
    RowClicked,
    onBtnExport,
    RemoveSpecialCharacters,
    Reset,
  },
  computed: {
    fields,
    formatedItems,
    ...mapState({
      
    })
  },
  watch: {
    active: function (NewVal) {
      if (NewVal) {
        this.getStowagePlanningFileGeneralCargo();
      }else{
        this.Reset();
      }
    }
  }
};
</script>